import { type FunctionComponent, useEffect, useState } from "react";
import {
  AnimatePresence,
  Button,
  type ButtonProps,
  type ColorTokens,
  Spinner,
  Stack,
  type ThemeName,
  XStack,
} from "tamagui";

import { type IconProps } from "@tamagui/helpers-icon";

import { HapticsService } from "../utils";

export type LoadingButtonProps = ButtonProps & {
  theme?: ThemeName;
  loading?: boolean;
  gradientOff?: boolean;
  icon?: FunctionComponent<IconProps>;
  stickyLoading?: boolean;
  disableHaptic?: boolean;
  customHaptic?: () => void;
  bold?: boolean;
};

/**
 * This is our main button component. By default we use our primary gradient
 * use gradientOff to turn off the gradient and then apply any custom colors
 * or theming you want. To include an icon simply use the icon prop and pass.
 *
 * StickyLoading: sometimes the loading prop finishes put pushing a new screen is slow, when we know there cant be an error
 * we can use stickyLoading to keep the loading state for extra time. This should probably be improved in the future open to
 * ideas
 */
export const LoadingButton = (props: LoadingButtonProps) => {
  const {
    loading,
    stickyLoading,
    disableHaptic,
    customHaptic,
    disabled,
    icon: Icon,
    color = "$color",
    height = "$5.5",
    bold = true,
    ...rest
  } = props;
  const [sticky, setSticky] = useState(loading);
  const haptic =
    customHaptic !== undefined ? customHaptic : HapticsService.selection;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (stickyLoading && sticky == true) {
      timeoutId = setTimeout(() => {
        setSticky(loading);
      }, 200);
    } else {
      setSticky(loading);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loading, stickyLoading]);

  return (
    <Button
      height={height}
      padding={0}
      borderWidth={0}
      disabled={disabled}
      br={100}
      animation={{
        scale: "bouncyTight",
        opacity: "bouncyTight",
        backgroundColor: "200ms",
        color: "200ms",
      }}
      disabledStyle={{
        backgroundColor: "$cream7",
      }}
      pressStyle={{
        scale: 0.95,
        opacity: 0.8,
      }}
      focusStyle={{
        outlineWidth: 0,
        outlineStyle: "none",
      }}
      onPressOut={() => {
        !disableHaptic && void haptic();
      }}
      {...rest}
    >
      <AnimatePresence>
        {sticky ? (
          <Stack
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            jc="center"
            ai="center"
            gap="$4"
            exitStyle={{
              opacity: 0,
            }}
            animation="quick"
            key="loading-stack"
          >
            <Spinner
              f={0}
              color={color as ColorTokens}
              key="loading-spinner"
              y={0}
              animation="quick"
            />
          </Stack>
        ) : (
          <XStack
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            jc="center"
            ai="center"
            gap="$4"
            animation="quick"
            exitStyle={{
              opacity: 0,
            }}
            key="text-stack"
          >
            {Icon ? (
              <Button.Icon scaleIcon={props.scaleIcon}>
                {/** @ts-expect-error color cant infer prop type correctly*/}
                <Icon color={disabled ? "$color3" : color}></Icon>
              </Button.Icon>
            ) : null}
            <Button.Text
              unstyled
              animation="200ms"
              color={disabled ? "#ADA8A0" : color}
              fontWeight={bold ? "500" : "400"}
            >
              {props.children}
            </Button.Text>
          </XStack>
        )}
      </AnimatePresence>
    </Button>
  );
};

export const TextButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      bw={0}
      backgroundColor="transparent"
      pressStyle={{
        bg: "transparent",
        scale: 0.95,
        opacity: 0.9,
      }}
      hoverStyle={{
        bg: "transparent",
      }}
      color="$textSecondary"
      br="$3"
      w="100%"
      {...props}
    />
  );
};

export const SecondaryButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      bw={0}
      backgroundColor="$groupedSecondary"
      pressStyle={{
        backgroundColor: "$groupedSecondary",
        scale: 0.95,
        opacity: 0.9,
      }}
      color="$navy"
      br="$10"
      {...props}
    />
  );
};

export const OutlinedButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      bw={1}
      borderColor="$gray3"
      backgroundColor="transparent"
      pressStyle={{
        bg: "$navy1",
        scale: 0.95,
        opacity: 0.9,
      }}
      color="$navy"
      br="$10"
      hoverStyle={{
        bg: "transparent",
      }}
      {...props}
    />
  );
};
